import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { getApolloErrorMessages, ICookieOptionalAttributesInput, useCookie } from '@netfront/common-library';
import {
  convertDBUser,
  DEFAULT_STORAGE_EXPIRY_OPTION,
  DEFAULT_STORAGE_EXPIRY_OPTIONS,
  IDBInvitation,
  LOCALHOST,
  useAcceptInvitation,
  useDomain,
  useGetGeladaProjectByDomain,
  useGetProduct,
} from '@netfront/gelada-identity-library';
import { Button } from '@netfront/ui-library';
import { LocalDomainSelector } from 'components';
import { useRouter } from 'next/router';
import { getSingularOrgProjectRedirectUrl, useLoginRedirect, useUpdateAccessToken } from 'utils';

import styles from './AcceptInvitationPage.module.css';

import { AcceptInvitationForm, AcceptInvitationWithLoginForm, IAcceptInvitationParams } from '../../../components/Forms';
import { Layout } from '../../../components/Shared/Layout';
import { CachingEntitiesContext, UserContext } from '../../../context';
import { useAcceptInvitationWithLoginDetails, useCodeQuerystringValidation, useGetInvitation, useToast } from '../../../hooks';

const AcceptInvitationPage = () => {
  const { code } = useCodeQuerystringValidation();
  const { getDomain, isDomainReady } = useDomain();
  const {
    createAccessTokenCookie,
    createCustomBuildProjectGuidCookie,
    createOrganisationIdCookie,
    createProjectGuidCookie,
    createProjectLogoUrlCookie,
    createProjectNameCookie,
    createRedirectAfterLoginUrlCookie,
    createRefreshTokenCookie,
    createUserDataCookie,
    getCustomBuildProjectGuidCookie,
    getRedirectAfterLoginUrlCookie,
    isSecureCookie,
    createProjectDomainCookie,
  } = useCookie();
  const { getProduct } = useGetProduct();
  const { handleToastError, handleToastSuccess } = useToast();
  const { push } = useRouter();
  const { handleLoginRedirect } = useLoginRedirect();

  const { setStoredUser } = useContext(UserContext);
  const { dashboardUrl: storedDashboardUrl, refreshProjectDetails } = useContext(CachingEntitiesContext);
  const { updateRefreshToken } = useUpdateAccessToken();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [invitation, setInvitation] = useState<IDBInvitation | undefined>();
  const [isActivationCompleted, setIsActivationCompleted] = useState<boolean>(false);
  const [isInvitationAccepted, setIsInvitationAccepted] = useState<boolean>(false);
  const [websiteUrl, setWebsiteUrl] = useState<string>();
  const [isProjectCustomBuild, setIsProjectCustomBuild] = useState<boolean>(false);
  const [dashboardUrl, setDashboardUrl] = useState<string>('');
  const [hasLocalHostDomainSelector, setHasLocalHostDomainSelector] = useState<boolean>(false);
  const [selectedLocalHostDomain, setSelectedLocalHostDomain] = useState<string>();

  const { handleGetGeladaProjectByDomain } = useGetGeladaProjectByDomain({
    onCompleted: ({ geladaProject: { domain: projectDomain, id, isCustomBuild, logo, name, organisationId } }) => {
      const { redirectAfterLogin, value: projectDomainValue, website } = projectDomain ?? {};
      const { presignedUrl } = logo ?? {};

      const domain = getDomain();
      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      const optionalCookieAttributesInput: ICookieOptionalAttributesInput = {
        domain,
        secure: isSecure,
        expiryOptions: {
          storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTION,
        }
      };

      if (isCustomBuild) {
        setIsProjectCustomBuild(isCustomBuild);
        createCustomBuildProjectGuidCookie({
          optionalCookieAttributesInput,
          value: id,
        });
      }

      if (projectDomainValue) {
        createProjectDomainCookie({
          optionalCookieAttributesInput,
          value: projectDomainValue,
        });
      }

      createOrganisationIdCookie({
        optionalCookieAttributesInput,
        value: String(organisationId),
      });

      createProjectGuidCookie({
        optionalCookieAttributesInput,
        value: id,
      });

      if (presignedUrl) {
        createProjectLogoUrlCookie({
          optionalCookieAttributesInput,
          value: encodeURIComponent(presignedUrl),
        });
      }

      createProjectNameCookie({
        optionalCookieAttributesInput,
        value: name,
      });

      if (website) {
        setWebsiteUrl(website);
      }

      if (redirectAfterLogin) {
        createRedirectAfterLoginUrlCookie({
          optionalCookieAttributesInput,
          value: encodeURIComponent(redirectAfterLogin),
        });
      }
      
      refreshProjectDetails();
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetInvitation, isLoading: isGetInvitationLoading = false } = useGetInvitation({
    onCompleted: (data) => {
      const { invitation: response } = data;
      const { invitedUserId } = response;
      if (invitedUserId) {
        setErrorMessage('Invitation already accepted');
        setTimeout(() => {
          push('/login').catch((error) => handleToastError({ error }));
        }, 1000);
        return;
      }

      setInvitation(response);
    },
    onError: (error: ApolloError) => {
      handleToastError({ error, shouldUseFriendlyErrorMessage: true });
      setErrorMessage(getApolloErrorMessages(error)[0]);
    },
  });

  const { handleAcceptInvitationWithLoginDetails, isLoading: isAcceptInvitationWithLoginDetailsLoading = false } =
    useAcceptInvitationWithLoginDetails({
      onCompleted() {
        updateRefreshToken(() => {
          setIsInvitationAccepted(true);
        });
      },
      onError: (error: ApolloError) => {
        handleToastError({ error, shouldUseFriendlyErrorMessage: true });
      },
    });

  const { handleAcceptInvitation, isLoading: isAcceptingInvitationLoading = false } = useAcceptInvitation({
    onCompleted: ({ accessToken, refreshToken, user }) => {
      handleToastSuccess({
        message: 'Your account has been successfully activated.',
      });

      const domain = getDomain();

      const isSecure = isSecureCookie(process.env.REACT_APP_COOKIE_ATTRIBUTE_SECURE);

      if (accessToken) {
        // The createXXXCookie functions all validate that a cookie doesn't exceed the maximum cookie size
        // We only expect the access token to potentially exceed the maximum cookie size hence this is the
        // only function that will be wrapped inside a try/catch block

        try {
          createAccessTokenCookie({
            optionalCookieAttributesInput: {
              domain,
              secure: isSecure,
              expiryOptions: {
                storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.accessToken,
              }
            },
            value: accessToken,
          });
        } catch (error) {
          handleToastError({
            error: error as Error,
            shouldUseFriendlyErrorMessage: true,
          });

          return;
        }
      }

      if (refreshToken) {
        createRefreshTokenCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.refreshToken,
            }
          },
          value: refreshToken,
        });
      }

      if (user) {
        const updatedUser = convertDBUser(user);

        const {
          credential: { email },
          firstName,
          id,
          lastName,
          memberships,
        } = updatedUser;

        setStoredUser(updatedUser);


        /*
         * Note
         *  - The whole user object is too large to store in a cookie
         *  - Extract only the important user data to store in a cookie
         */

        createUserDataCookie({
          optionalCookieAttributesInput: {
            domain,
            secure: isSecure,
            expiryOptions: {
              storageExpiryOptions: DEFAULT_STORAGE_EXPIRY_OPTIONS.userData,
            }
          },
          value: JSON.stringify({
            email,
            firstName,
            id,
            lastName,
          }),
        });

        setIsActivationCompleted(true);

        if (invitation?.type === 'GROUP') {
          if (websiteUrl) {
            push(websiteUrl).catch((error) => {
              handleToastError({
                error,
                shouldUseFriendlyErrorMessage: true,
              });
            });
          }
          return;
        }

        updateRefreshToken(() => {
          let customRedirectUrl = '';

          if (invitation && Boolean(invitation.projectId)) {
            const { organisation: { key: organisationKey }, project: { id: projectId = '' } } = invitation;
            customRedirectUrl = `${String(dashboardUrl)}/${organisationKey}/${projectId}`;
          }

          const redirectAfterLogin = getRedirectAfterLoginUrlCookie();
          const singleOrgAndProjectRedirectUrl = getSingularOrgProjectRedirectUrl(memberships);

          let redirectIfSingularOrgProjectUrl = '';

          if (singleOrgAndProjectRedirectUrl) redirectIfSingularOrgProjectUrl = `${String(dashboardUrl)}/${singleOrgAndProjectRedirectUrl}`;

          handleLoginRedirect({
            isCustomBuild: isProjectCustomBuild,
            isUserAProjectAdmin: invitation?.type === "PROJECT" && invitation.permission !== 'NONE',
            projectWebsiteUrl: websiteUrl,
            redirectAfterLoginUrl: redirectAfterLogin,
            redirectIfSingularOrgProjectUrl,
            customRedirectUrl,
          });
        });
      }
    },
    onError: (error: ApolloError) => {

      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });

      setErrorMessage(getApolloErrorMessages(error)[0]);
    },
    product: getProduct(),
    projectId: getCustomBuildProjectGuidCookie(),
  });

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    
    if (getDomain() === LOCALHOST) {
      setHasLocalHostDomainSelector(true);
      return;
    }


    void handleGetGeladaProjectByDomain({
      domain: getDomain(),
      shouldIncludeProjectDomain: true,
      shouldIncludeProjectLogo: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);

  useEffect(() => {
    if (!(selectedLocalHostDomain && code)) {
      return;
    }

    const domain = getDomain();

    if (domain !== LOCALHOST) {
      return;
    }

    void handleGetGeladaProjectByDomain({
      domain: selectedLocalHostDomain,
    });

    void handleGetInvitation({
      token: code,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocalHostDomain, code]);

  useEffect(() => {
    if (!isDomainReady) return;

    const domain = getDomain();
    if (domain === LOCALHOST) {
      return;
    }

    if (!code) {
      return;
    }

    void handleGetInvitation({
      token: code,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, isDomainReady]);

  useEffect(() => {
    if (!(isDomainReady && storedDashboardUrl)) {
      return;
    }

    setDashboardUrl(String(storedDashboardUrl));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady, storedDashboardUrl, selectedLocalHostDomain]);

  const onLoginClick = () => {
    push('/login').catch((error) => handleToastError({ error }));
  };

  const onAcceptInvitationFormSubmit = ({ password }: IAcceptInvitationParams) => {
    void handleAcceptInvitation({
      request: {
        password: password,
        tokenId: String(code),
      },
      shouldIncludeUserMemberships: true,
    });
  };

  const isLoading = isAcceptingInvitationLoading || isGetInvitationLoading || isAcceptInvitationWithLoginDetailsLoading;

  return (
    <Layout
      headerText="Accept invitation"
      isPreloaderVisible={isLoading}
      pageDescription={!isActivationCompleted ? 'Create a Password' : ''}
      title={!isActivationCompleted ? 'Accept invitation' : 'Login'}
      hasSmallHeaderText
    >
      {hasLocalHostDomainSelector && !selectedLocalHostDomain ? (
        <LocalDomainSelector
          isVisible={true}
          onChange={setSelectedLocalHostDomain}
        />
      ): (
        <>
          {!errorMessage && !isAcceptingInvitationLoading && !isInvitationAccepted && invitation?.userExists && (
            <AcceptInvitationWithLoginForm
              buttonText="Accept"
              invitation={invitation}
              isSubmitting={isLoading}
              onSubmit={({ email, password }) => {
                void handleAcceptInvitationWithLoginDetails({ email, password, token: String(code) });
              }}
            />
          )}
          {!errorMessage && !isAcceptingInvitationLoading && !isActivationCompleted && !invitation?.userExists && (
            <AcceptInvitationForm buttonText="Activate" isSubmitting={isLoading} onSubmit={onAcceptInvitationFormSubmit} />
          )}
          <div className={styles['c-activation-page']}>
            {isAcceptingInvitationLoading && !errorMessage && (
              <>
                <h2>Activating account</h2>

                <div>Please wait...</div>
              </>
            )}
            {isInvitationAccepted && !errorMessage && (
              <>
                <h2>The invitation has been accepted!</h2>
                <p>You can now login to your account.</p>
                <Button text="Login" type="submit" onClick={onLoginClick} />
              </>
            )}
            {isActivationCompleted && !errorMessage && (
              <>
                <h2>Thank you!</h2>

                <p>Your account has been activated.</p>
                <Button text="Login" type="submit" onClick={onLoginClick} />
              </>
            )}

            {errorMessage && (
              <>
                <h2>{errorMessage}</h2>
                <Button text="Login" type="submit" onClick={onLoginClick} />
              </>
            )}
          </div>

        </>

      )}
      
    </Layout>
  );
};

export { AcceptInvitationPage };
