import { useContext, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateUserAsAdmin } from '@netfront/gelada-identity-library';
import { ControlledForm, Dialog, Input, SidebarButtons, Spinner, useControlledForm, FormFieldProps } from '@netfront/ui-library';
import { UserContext } from 'context';
import { useRouter } from 'next/router';
import { Control, Controller, ControllerFieldState } from 'react-hook-form';
import { useLastVisitedOrganisationCookie } from 'utils';
import * as yup from 'yup';

import { SidebarContainer } from 'components/Shared';



import { ManageUsersGeneralViewProps } from './ManageUsersGeneralView.interfaces';

import { INTERNAL_URLS } from '../../../constants';
import { useRemoveUserFromOrganisation, useToast } from '../../../hooks';


const ManageUsersGeneralView = ({ onClose, onDeleted, onUpdated, user, organisationId }: ManageUsersGeneralViewProps) => {
  const { handleToastError } = useToast();
  const { push } = useRouter();
  const { user: currentUser } = useContext(UserContext);
  const { removeUserLastVisitedOrganisation } = useLastVisitedOrganisationCookie();

  const [fullName, setFullName] = useState<string>('');
  const [id, setId] = useState<number>();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  
  

  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { control, handleSubmit, reset, getValues } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        firstName: yup.string().label('First name').required(),
        lastName: yup.string().label('Last name').required(),
        email: yup.string().label('Email').email('Please enter a valid email').required(),
      }),
    ),
  });

  const { handleRemoveUserFromOrganisation, isLoading: isDeleteUsersLoading = false } = useRemoveUserFromOrganisation({
    onCompleted: () => {
      if (user.id === currentUser?.id) {
        removeUserLastVisitedOrganisation(String(currentUser.id));
        push(INTERNAL_URLS.dashboard).catch((error) => {
          handleToastError({
            error,
            shouldUseFriendlyErrorMessage: true,
          });
        });
      }
      reset();
      onDeleted();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const { handleUpdateUserAsAdmin, isLoading: isUpdateUserAsAdminLoading = false } = useUpdateUserAsAdmin({
    onCompleted: () => {
      reset();
      onUpdated();
    },
    onError: (error) => {
      handleToastError({
        error,
      });
    },
  });

  const handleValidationError = ({ error, invalid }: ControllerFieldState): string => {
    if (!(error && invalid)) {
      return '';
    }

    const { message = '' } = error;

    return message;
  };


  const isLoading = isDeleteUsersLoading || isUpdateUserAsAdminLoading;
  
  useEffect(() => {
    
    const {
      communityName = '',
      credential: { email = '', phoneNumber = '' } = {},
      firstName = '',
      id: userId,
      lastName = '',
      status,
    } = user;

    setFullName(`${String(firstName)} ${String(lastName)}`.trim());
    setId(userId);

    setDefaultValues({
      communityName,
      firstName,
      email,
      phoneNumber,
      lastName,
      userId,
      status: status === 'ACTIVE',
    } as FormFieldProps);

  }, [user]);

  return (
    <SidebarContainer>
      <Spinner isLoading={isLoading} />
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          const { communityName, firstName, lastName, email, phoneNumber, userId } = item;

          void handleUpdateUserAsAdmin({
            communityName,
            email,
            firstName,
            lastName,
            phoneNumber,
            userId,
          });
        }}
        handleSubmit={handleSubmit}
      >      
        <Controller
          control={control as Control<FormFieldProps>}
          name="firstName"
          render={({ field, fieldState }) => (
            <Input 
              errorMessage={handleValidationError(fieldState)} 
              id="id_first_name" 
              labelText="First name" 
              type="text"
              isDisabled
              isLabelSideBySide
              isReadOnly
              isRequired
              {...field}
              onChange={(event) => {
                const { target: { value }} = event;
                const { lastName } = getValues();
                setFullName(`${String(value)} ${String(lastName)}`.trim())
                field.onChange(event);
              }}
            />
          )}
        />

        <Controller
          control={control as Control<FormFieldProps>}
          name="lastName"
          render={({ field, fieldState }) => (
            <Input 
              errorMessage={handleValidationError(fieldState)} 
              id="id_last_name" 
              labelText="Last name" 
              type="text"
              isDisabled
              isLabelSideBySide
              isReadOnly
              isRequired
              {...field}
              onChange={(event) => {
                const { target: { value }} = event;
                const { firstName } = getValues();
                setFullName(`${String(firstName)} ${String(value)}`.trim())
                field.onChange(event);
              }}
            />
          )}
        
        />
        
        <Controller
          control={control as Control<FormFieldProps>}
          name="email"
          render={({ field, fieldState }) => (
            <Input 
              errorMessage={handleValidationError(fieldState)} 
              id="id_email" 
              labelText="Email" 
              type="text"
              isDisabled
              isLabelSideBySide
              isReadOnly
              isRequired
              {...field}
            />
          )}
        />

        <Controller
          control={control as Control<FormFieldProps>}
          name="phoneNumber"
          render={({ field }) => (
            <Input 
              id="id_phone_number" 
              labelText="Phone number" 
              type="text" 
              isDisabled
              isLabelSideBySide
              isReadOnly
              {...field}
            />
          )}
        />

      
        <SidebarButtons
          buttonSize="xs"
          deleteButtonText="Remove"
          submitButtonText="Update"
          isSaveButtonDisabled
          onCancel={onClose}
          onDelete={() => setIsDeleteDialogOpen(true)}
          onSaveButtonType="submit"
        />
      </ControlledForm>
      <Dialog
        deleteButtonText='Remove'
        isOpen={isDeleteDialogOpen}
        title={`Remove ${fullName} from organisation?`}
        isNarrow
        onCancel={() => setIsDeleteDialogOpen(false)}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={() => {
          if (!id) return;
          void handleRemoveUserFromOrganisation({
            organisationId: Number(organisationId),
            userId: id,
          });
        }}
      />
    </SidebarContainer>
  );
};

export { ManageUsersGeneralView };
