//TODO:  Un comment status when status is updatable on updateUserType mutation

import { useEffect, useState, FormEvent } from 'react';

import { useLoggedGeladaLazyQuery, useLoggedGeladaMutation } from '@netfront/gelada-identity-library';
import { Dialog, Input, RadioButtonGroup, SidebarButtons, Spinner, ToggleSwitch } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { SidebarContainer } from 'components/Shared';

import { USER_TYPE_STATUS } from './UserTypeGeneralTab.constants';
import { UserTypeGeneralTabProps } from './UserTypeGeneralTab.interfaces';

import { CREATE_USER_TYPE, DELETE_USER_TYPE, GET_USER_TYPE, UPDATE_USER_TYPE } from '../../../../graphql';
import { useToast } from '../../../../hooks';

const UserTypeGeneralTab = ({ selectedUserTypeId, onClose, onUpdate }: UserTypeGeneralTabProps) => {
  const {
    query: { projectId: queryProjectId },
  } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isUserTypeStatusActive, setIsUserTypeStatusActive] = useState<boolean>(false);
  const [userTypePermission, setUserTypePermission] = useState<string>('READ');
  const [userTypeRole, setUserTypeRole] = useState<string>();

  const [getUserType, { loading: isGetUserTypeLoading }] = useLoggedGeladaLazyQuery({
    options: {
      fetchPolicy: 'cache-and-network',
      onCompleted: ({
        userType: {
          getUserType: { name: role, permission, status },
        },
      }) => {
        setUserTypePermission(String(permission));
        setUserTypeRole(String(role));
        setIsUserTypeStatusActive(Boolean(USER_TYPE_STATUS[status]));
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    query: GET_USER_TYPE,
  });

  const [createUserType, { loading: isUpdateCreateUserTypeLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onUpdate();

        handleToastSuccess({
          message: 'Create success',
        });

        onClose();
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: CREATE_USER_TYPE,
  });

  const [updateUserType, { loading: isUpdateUserTypeLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onUpdate();

        handleToastSuccess({
          message: 'Update success',
        });

        onClose();
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: UPDATE_USER_TYPE,
  });

  const [deleteUserType, { loading: isDeleteUserTypeLoading }] = useLoggedGeladaMutation({
    options: {
      onCompleted: () => {
        onUpdate();

        handleToastSuccess({
          message: 'Delete success',
        });

        onClose();
      },
      onError: (error) => {
        handleToastError({
          error,
        });
      },
    },
    mutation: DELETE_USER_TYPE,
  });

  const handleUpdateUserTypeStatus = () => {
    setIsUserTypeStatusActive(!isUserTypeStatusActive);
  };

  const handleCreateUserType = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    void createUserType({
      variables: {
        approvalRequired: false,
        name: userTypeRole,
        permission: userTypePermission,
        projectId,
      },
    });
  };

  const handleUpdateUserType = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    void updateUserType({
      variables: {
        approvalRequired: false,
        name: userTypeRole,
        permission: userTypePermission,
        status: isUserTypeStatusActive ? 'ENABLED' : 'DISABLED',
        userTypeId: selectedUserTypeId,
      },
    });
  };

  const handleUpdateUserTypeRole = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUserTypeRole(value);
  };

  const handlePermissionsChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setUserTypePermission(value);
  };

  useEffect(() => {
    if (!selectedUserTypeId) {
      return;
    }

    void getUserType({
      variables: {
        userTypeId: selectedUserTypeId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTypeId]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  const isLoading = isGetUserTypeLoading || isUpdateCreateUserTypeLoading || isUpdateUserTypeLoading || isDeleteUserTypeLoading;

  return (
    <SidebarContainer>

      <form onSubmit={selectedUserTypeId ? handleUpdateUserType : handleCreateUserType}>
        <Spinner isLoading={isLoading} />

        <Input
          id="role"
          labelText="Role"
          name="userTypeRole"
          type="text"
          value={userTypeRole}
          isLabelSideBySide
          isRequired
          onChange={handleUpdateUserTypeRole}
        />

        <RadioButtonGroup
          items={[
            { labelText: 'Manage users', id: 'MANAGE_USERS', value: 'MANAGE_USERS' },
            { labelText: 'Edit', id: 'EDIT', value: 'EDIT' },
            { labelText: 'View', id: 'READ', value: 'READ' },
          ]}
          name="permissions"
          selectedValue={userTypePermission}
          title="Permissions"
          isLabelSideBySide
          onChange={handlePermissionsChange}
        />

        {Boolean(selectedUserTypeId) && (
          <>
            <ToggleSwitch
              id="user-type-status"
              isChecked={isUserTypeStatusActive}
              labelText="Status"
              isLabelSideBySide
              onChange={handleUpdateUserTypeStatus}
            />


            <Dialog
              isOpen={isDeleteDialogOpen}
              title={`Delete user type: ${String(userTypeRole)}?`}
              isNarrow
              onCancel={() => setIsDeleteDialogOpen(false)}
              onClose={() => setIsDeleteDialogOpen(false)}
              onConfirm={() => {
                void deleteUserType({
                  variables: {
                    userTypeId: selectedUserTypeId,
                  },
                });
              }}
            />

          </>
        )}

        <SidebarButtons
          buttonSize="xs"
          onCancel={onClose}
          onDelete={selectedUserTypeId ? () => setIsDeleteDialogOpen(true) : undefined}
          onSaveButtonType="submit"
        />
      </form>
    </SidebarContainer>
  );
};

export { UserTypeGeneralTab };
