import React, { useState, useEffect, useContext } from 'react';

import { handleApolloError, IDropDownListOption } from '@netfront/ekardo-content-library';
import { IDBInvitation } from '@netfront/gelada-identity-library';
import { GeneralTabIcon, GroupsTabIcon, ITab, SelectWithSearch } from '@netfront/ui-library';
import last from 'lodash.last';
import { useRouter } from 'next/router';
import pluralize from 'pluralize';

import { BulkActionDialog } from 'components/Dialogs';

import { PROJECT_USER_INVITATION_TABLE_COLUMNS, PROJECT_EKARDO_USER_INVITATION_TABLE_COLUMNS } from './ProjectUserInvitations.constants';
import { getInvitationTableData } from './ProjectUserInvitations.helpers';

import { InvitationsBulkInviteTab, InvitationsGeneralTab } from '../../../../components/Tabs';
import { CachingEntitiesContext } from '../../../../context';
import {
  useToast,
  useGetPaginatedGroupInvitations,
  IGetPaginatedGroupInvitationsOnCompletedResponse,
  useGetPaginatedGroups,
} from '../../../../hooks';
import { IGroupInvitationTableData } from '../../../Pages/Pages.interfaces';
import { USER_MANAGEMENT_PAGE_CONSTANTS } from '../../../Pages/UserManagementPages/UserManagementPages.constants';
import { TablePageTemplate } from '../../../Templates/TablePageTemplate';

const { pageTitle } = USER_MANAGEMENT_PAGE_CONSTANTS;

const ProjectUserInvitations = () => {
  const { project, isProduct, hasGroups } = useContext(CachingEntitiesContext);

  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const [projectName, setProjectName] = useState<string>('');
  const [projectId, setProjectId] = useState<string>('');

  const { handleToastError, handleToastSuccess } = useToast();

  const { defaultPageSize } = USER_MANAGEMENT_PAGE_CONSTANTS;

  const [allGroupInvitations, setAllGroupInvitations] = useState<IDBInvitation[]>();
  const [filter, setFilter] = useState<string>('');
  const [isAddNewInvitation, setIsAddNewInvitation] = useState<boolean>(false);
  const [groupInvitationsTableData, setGroupInvitationsTableData] = useState<IGroupInvitationTableData[]>([]);
  const [groupsSearchListDropdownItems, setGroupsSearchListDropdownItems] = useState<IDropDownListOption[]>();
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isSearchContentVisible, setSearchIsContentVisible] = useState<boolean>(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [lastCursor, setLastCursor] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [selectedGroupIdString, setSelectedGroupIdString] = useState<string>();
  const [selectedInvitation, setSelectedInvitation] = useState<IDBInvitation>();
  const [totalInvitations, setTotalInvitations] = useState<number>(0);

  const [totalGroups, setTotalGroups] = useState<number>(0);
  const [isLoadMoreGroupsDisabled, setIsLoadMoreGroupsDisabled] = useState<boolean>(false);
  const [groupFilter, setGroupFilter] = useState<string>();
  const [lastGroupCursor, setLastGroupCursor] = useState<string>();
  const [selectedGroupLabel, setSelectedGroupLabel] = useState<string>('All groups');

  // bulk actions
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [bulkActionType, setBulkActionType] = useState<string>('delete');
  const [isBulkActionDialogOpen, setIsBulkActionDialogOpen] = useState<boolean>(false);

  const {
    handleGetPaginatedGroupInvitations,
    handleFetchMorePaginatedGroupInvitations,
    isLoading: isGetPaginatedGroupInvitationsLoading = false,
  } = useGetPaginatedGroupInvitations({
    onCompleted: ({ groupInvitationConnection: { edges, totalCount = 0 } }: IGetPaginatedGroupInvitationsOnCompletedResponse) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastCursor) {
        setLastCursor(lastEdge.cursor);
      }

      const groupInvitations = edges.map(({ node }) => node);

      setAllGroupInvitations(groupInvitations);
      setIsLoadMoreDisabled(groupInvitations.length >= totalCount || totalCount <= pageSize);
      setTotalInvitations(totalCount);
    },
    onError: (error) => handleApolloError(error),
  });

  const { handleFetchMorePaginatedGroups, handleGetPaginatedGroups } = useGetPaginatedGroups({
    onCompleted: ({ groupConnection: { edges, totalCount = 0 } }) => {
      const lastEdge = last(edges);

      if (lastEdge && lastEdge.cursor !== lastGroupCursor) {
        setLastGroupCursor(lastEdge.cursor);
      }

      const groups = edges.map(({ node }) => node);

      const groupsSelectSearchList = groups.map(
        ({ id, name }): IDropDownListOption => ({
          id: String(id),
          label: name,
          value: String(id),
        }),
      );

      setGroupsSearchListDropdownItems([
        {
          id: '0',
          label: 'All groups',
          value: '0',
        },
        ...groupsSelectSearchList,
      ]);

      setIsLoadMoreGroupsDisabled(groups.length >= totalCount || totalCount <= pageSize);
      setTotalGroups(totalCount);
    },
    onError: (error) => {
      handleToastError({
        error,
        hasCloseButton: true,
      });
    },
  });

  const handlePageSizeChange = (changedPageSize: number) => {
    setSearchIsContentVisible(false);
    setPageSize(Number(changedPageSize));
  };

  const handleSideBarClose = () => {
    setIsAddNewInvitation(false);
    setIsSideBarOpen(false);
    setSelectedInvitation(undefined);
  };

  const handleUpdateInvitation = () => {
    void handleGetPaginatedGroupInvitations({
      filter,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(projectId),
    });

    handleSideBarClose();
  };

  const handleDeleteInvitation = () => {
    handleToastSuccess({
      message: 'Invitation deleted successfully',
    });
    handleSideBarClose();

    void handleGetPaginatedGroupInvitations({
      filter,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(projectId),
    });
  };

  const handleGroupSearchItemClick = (id: string | number) => {
    const groupId = Number(id);

    if (!groupId) {
      setSelectedGroupId(undefined);
      return;
    }

    setSelectedGroupId(groupId);
    setSelectedGroupIdString(String(id));
  };

  const handleAddNewInvitationClick = () => {
    setIsAddNewInvitation(true);
    setSearchIsContentVisible(false);
    setSelectedInvitation(undefined);
    setIsSideBarOpen(true);
  };

  const handleGetInvitations = () => {
    setSearchIsContentVisible(false);

    void handleGetPaginatedGroupInvitations({
      filter,
      first: pageSize,
      projectId: String(projectId),
    });
  };

  const handleFilterSearch = (value: string) => {
    void handleGetPaginatedGroupInvitations({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
    });

    setFilter(value);
  };

  const handleGroupSearch = (value: string) => {
    void handleGetPaginatedGroups({
      filter: value,
      first: pageSize,
      projectId: String(projectId),
    });

    setGroupFilter(value);
  };

  const handleLoadMoreGroups = () => {
    void handleFetchMorePaginatedGroups({
      filter: groupFilter,
      after: lastGroupCursor,
      first: pageSize,
      projectId: String(projectId),
    });
  };

  // bulk actions
  const handleOpenBulkActionDialog = (type: string) => {
    setBulkActionType(type);
    setIsBulkActionDialogOpen(true);
  };

  const handleCloseBulkActionDialog = () => {
    setBulkActionType('');
    setIsBulkActionDialogOpen(false);
  };

  const handleSelectedRows = (selectedIds: string[]) => {
    setSelectedRows(selectedIds);
  };

  const handleBulkActionSave = () => {
    setSelectedRows([]);
    handleCloseBulkActionDialog();
    void handleGetPaginatedGroupInvitations({
      filter: filter,
      first: pageSize,
      projectId: String(projectId),
    });
  };

  useEffect(() => {
    if (!projectId) {
      return;
    }

    if (hasGroups) {
      void handleGetPaginatedGroups({
        first: pageSize,
        projectId: String(projectId),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, hasGroups]);

  useEffect(() => {
    if (!allGroupInvitations) {
      return;
    }

    const allInvitations = allGroupInvitations.flatMap((invitation) => [invitation]);

    setGroupInvitationsTableData(
      getInvitationTableData({
        invites: allInvitations,
        onSettingsButtonClick: (id) => {
          setIsSideBarOpen(true);
          setSelectedInvitation(allInvitations.find(({ id: invitationId }) => id === invitationId));
        },
      }),
    );
  }, [allGroupInvitations]);

  useEffect(() => {
    if (!project) {
      return;
    }

    void handleGetPaginatedGroupInvitations({
      filter,
      first: pageSize,
      groupId: selectedGroupId,
      projectId: String(project.id),
    });

    const { name } = project;
    if (!projectName) setProjectName(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, project, selectedGroupId]);

  useEffect(() => {
    const selectedGroup = groupsSearchListDropdownItems?.find((item) => item.id === String(selectedGroupIdString));

    setSelectedGroupLabel(selectedGroup ? selectedGroup.label : 'All groups');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupsSearchListDropdownItems, selectedGroupIdString]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);


  const addTabs: ITab[] = [
    {
      icon: GeneralTabIcon,
      id: 'id_general_tab',
      label: 'General',
      view: () => (
        <InvitationsGeneralTab
          hasGroups={hasGroups}
          selectedInvitation={selectedInvitation}
          hasAllGroupsOption
          onClose={handleSideBarClose}
          onDeleted={handleDeleteInvitation}
          onUpdated={handleUpdateInvitation}
        />
      ),
    },
    {
      icon: GroupsTabIcon,
      id: 'id_bulk_tab',
      label: 'Bulk invite',
      view: () => (
        <InvitationsBulkInviteTab
          hasAllGroupsOption
          onClose={handleSideBarClose}
          onUpdate={handleGetInvitations}
        />
      ),
    },
  ];

  const editTabs: ITab[] = selectedInvitation ?
    [
      {
        icon: GeneralTabIcon,
        id: 'id_general_tab',
        label: 'General',
        view: () => (
          <InvitationsGeneralTab
            hasGroups={hasGroups}
            selectedInvitation={selectedInvitation}
            hasAllGroupsOption
            onClose={handleSideBarClose}
            onDeleted={handleDeleteInvitation}
            onUpdated={handleUpdateInvitation}
          />
        ),
      }
    ] : [];

  const isLoading = isGetPaginatedGroupInvitationsLoading;

  const tabs = isAddNewInvitation ? addTabs : editTabs;

  return (
    <>
      <TablePageTemplate<IGroupInvitationTableData>
        activePage={isProduct ? 'users' : 'invitations'}
        activeSubPage="invitations"
        additionalBreadcrumbItems={[
          {
            key: '3',
            content: <span>Project invitations</span>,
          },
        ]}
        bulkActions={[
          { id: 0, label: 'Delete invitations', action: () => handleOpenBulkActionDialog('deleteInvitations') },
        ]}
        childrenMiddle={
          hasGroups ? (
            <SelectWithSearch
              additionalClassNames="c-select-with-search__users-table"
              buttonText="All groups"
              countText="groups"
              defaultValue={selectedGroupLabel}
              hasLabelPadding={false}
              id="id_search_groups"
              isDisplaySearchContent={isSearchContentVisible}
              labelText="search groups"
              searchList={groupsSearchListDropdownItems ?? []}
              totalCount={totalGroups}
              isAvatarVisible
              isLabelHidden
              onDisplaySearchContent={() => setSearchIsContentVisible(!isSearchContentVisible)}
              onLoadMore={!isLoadMoreGroupsDisabled ? handleLoadMoreGroups : undefined}
              onSearch={handleGroupSearch}
              onSearchItemClick={handleGroupSearchItemClick}
            />
          ) : null
        }
        columns={hasGroups ? PROJECT_EKARDO_USER_INVITATION_TABLE_COLUMNS : PROJECT_USER_INVITATION_TABLE_COLUMNS}
        data={groupInvitationsTableData}
        description="Manage users"
        handleAddNewClick={handleAddNewInvitationClick}
        handleOnPageSizeChange={handlePageSizeChange}
        handleOnPaginate={async () => {
          await handleFetchMorePaginatedGroupInvitations({
            after: lastCursor,
            filter,
            first: pageSize,
            groupId: selectedGroupId,
            projectId: String(projectId),
          });
        }}
        handleSearch={handleFilterSearch}
        informationBoxMessage={
          <>
            Manage <strong>{String(projectName)}</strong> user invitations:{' '}
            <strong>
              {totalInvitations} {pluralize('invitation', totalInvitations)}
            </strong>
          </>
        }
        isLoading={isLoading}
        isPaginationDisabled={isLoadMoreDisabled}
        isSideBarOpen={isSideBarOpen}
        pageSize={pageSize}
        pageTitle={pageTitle}
        searchPlaceholder="Name, identifier or role"
        tableType="invitations"
        tabs={tabs}
        title={String(projectName)}
        toggleIsSideBarOpen={handleSideBarClose}
        totalItems={totalInvitations}
        isProjectLevel
        onSelectRows={handleSelectedRows}
      />

      {projectId && (
        <BulkActionDialog
          bulkActionType={bulkActionType}
          handleCloseDialog={handleCloseBulkActionDialog}
          isOpen={isBulkActionDialogOpen}
          projectId={String(projectId)}
          selectedIds={selectedRows}
          onSave={handleBulkActionSave}
        />
      )}
    </>
  );
};

export { ProjectUserInvitations };
