import { useContext } from 'react';

import {
  Pagination,
  SidebarNavigation,
  TablePageTemplate as NetfrontTablePageTemplate,
  SideBarTabSet,
  ITableRequiredParameters,
  PageContainer,
} from '@netfront/ui-library';
import Link from 'next/link';

import { TablePageTemplateProps } from './TablePageTemplate.interfaces';

import { PageLayout } from '../../../components';
import { INTERNAL_URLS } from '../../../constants';
import { CachingEntitiesContext, DashboardContext } from '../../../context';

const TablePageTemplate = <T extends ITableRequiredParameters>({
  additionalBreadcrumbItems = [],
  activePage,
  activeSubPage,
  bulkActions,
  bulkActionPlaceHolder,
  childrenEnd,
  childrenMiddle,
  columns,
  data,
  defaultActiveTabId = 'id_general_tab',
  description,
  handleAddNewClick,
  handleOnPageSizeChange,
  handleOnPaginate,
  handleSearch,
  informationBoxMessage,
  isLoading,
  isInternal = false,
  isPaginationDisabled = false,
  isSideBarOpen,
  onSelectRows,
  organisation,
  pageSize = 10,
  pageTitle,
  searchPlaceholder = '',
  tableType,
  tabs = [],
  title,
  toggleIsSideBarOpen,
  totalItems = 0,
  hideSideBarButtons = true,
  isProjectLevel = false,
  isOrganisationLevel = false,
}: TablePageTemplateProps<T>) => {
  const { project, dashboardUrl } = useContext(CachingEntitiesContext);
  const { navigationItems, organisationNavigationItems } = useContext(DashboardContext);
  
  return (
    <PageLayout
      isInternal={isInternal}
      isPreloaderVisible={isLoading}
      meta={{ description: description }}
      organisation={organisation}
      title={pageTitle}
      hasPrivateLayout
      isProtectedPage
    >
      <PageContainer>
        <SidebarNavigation
          activePage={activePage}
          activeSubPage={activeSubPage}
          dashboardLink={isProjectLevel ? `${String(dashboardUrl)}/${String(project?.organisation.key)}/${String(project?.id)}`: INTERNAL_URLS.dashboard }
          navigationItems={isOrganisationLevel ? organisationNavigationItems : navigationItems }
          offsetPositioning={0}
        >
          <NetfrontTablePageTemplate<T>
            addNewOnClick={handleAddNewClick}
            breadcrumbItems={[
              {
                key: '0',
                content: (
                  <Link href={isOrganisationLevel ? INTERNAL_URLS.dashboard: `${String(dashboardUrl)}/${String(project?.organisation.key)}/${String(project?.id)}`}>
                    <span>Dashboard</span>
                  </Link>
                ),
              },
              {
                key: '1',
                content: <span>{pageTitle}</span>,
              },
              ...additionalBreadcrumbItems,
            ]}
            bulkActionPlaceHolder={bulkActionPlaceHolder}
            bulkActions={bulkActions}
            childrenEnd={childrenEnd}
            childrenMiddle={childrenMiddle}
            columns={columns}
            data={data}
            emptyStateImageUrl="/images/empty-state.webp"
            informationBoxMessage={informationBoxMessage}
            isLoading={isLoading}
            searchPlaceholder={searchPlaceholder}
            tableType={tableType}
            title={title}
            onSearch={handleSearch}
            onSelectRows={onSelectRows}
          />

          {totalItems > pageSize && handleOnPaginate && handleOnPageSizeChange && (
            <Pagination
              additionalClassNames="c-pagination-user-management"
              isLoadMoreDisabled={isPaginationDisabled}
              pageSize={pageSize}
              totalItems={totalItems}
              onPageChange={handleOnPaginate}
              onPageSizeChange={handleOnPageSizeChange}
            />
          )}
        </SidebarNavigation>
      </PageContainer>
      <SideBarTabSet
        defaultActiveTabId={defaultActiveTabId}
        handleOpenCloseSideBar={
          toggleIsSideBarOpen
            ? toggleIsSideBarOpen
            : () => {
                return;
              }
        }
        hasViewPadding={false}
        hideSideBarButtons={hideSideBarButtons}
        isSideBarOpen={isSideBarOpen}
        tabs={tabs}
      />
    </PageLayout>
  );
};

export { TablePageTemplate };
